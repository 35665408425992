/** @jsx jsx */
import { jsx } from '@emotion/react';

import PropTypes from 'prop-types';
import { useState } from 'react';
import { PAGES__SHAPE_ITEM } from '../../../redux/constants';
import Button from '../../button/button';
import { bottomNavCss } from './bottom-nav.css';

export const BottomNav = ({
  answersBusy,
  answersDisabled,
  children,
  currentPageIndex,
  editable,
  page,
  showPreviousButton,
}) => {
  const [isPrevious, setIsPrevious] = useState(false);

  const shouldDisplayPreviousButton = editable
    ? showPreviousButton && page.position > 1 && page.nextButtonIsVisible
    : showPreviousButton && currentPageIndex > 0 && !page.terminating;

  if (!shouldDisplayPreviousButton && !page.nextButtonIsVisible) {
    return null;
  }

  return (
    <div css={bottomNavCss(!editable)}>
      {shouldDisplayPreviousButton ? (
        <Button
          className="btn-lg btn-prev"
          disabled={page.position < 2 || editable}
          name="formAction"
          type="submit"
          value="previous"
          sharp
          showPreloader={answersBusy && isPrevious}
          onClick={() => setIsPrevious(true)}
        >
          Previous
        </Button>
      ) : (
        <span />
      )}
      {children}
      {page.nextButtonIsVisible && (
        <Button
          className="btn-lg"
          disabled={answersDisabled}
          name="formAction"
          onClick={() => setIsPrevious(false)}
          sharp
          showPreloader={answersBusy && !isPrevious}
          type="submit"
          value="next"
        >
          Next
        </Button>
      )}
    </div>
  );
};

BottomNav.propTypes = {
  answersBusy: PropTypes.bool,
  answersDisabled: PropTypes.bool,
  children: PropTypes.node,
  currentPageIndex: PropTypes.number,
  editable: PropTypes.bool,
  page: PAGES__SHAPE_ITEM,
  showPreviousButton: PropTypes.bool.isRequired,
};

BottomNav.defaultProps = {
  answersBusy: false,
  answersDisabled: false,
  children: null,
  currentPageIndex: 0,
  editable: false,
  page: null,
};
